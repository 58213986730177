export const TYPE_PLAYLIST = 1;
export const TYPE_VIDEO = 2;
export const TYPE_QUIZ = 3;


export const STATUS_VIDEO_COMPLETE = 'COMPLETE';
export const STATUS_VIDEO_COMPLETE_THUMBNAIL = 'COMPLETE_IMAGE';
export const STATUS_VIDEO_MISSING_FILE = 'WAITING_FILE';
export const STATUS_VIDEO_PROCESSING = 'PROCESSING';
export const STATUS_VIDEO_PROCESSING_AWS = 'PROCESSING_AMAZON';
export const STATUS_VIDEO_UPDATE_STATUS = 'UPDATE_PROGRESS';
export const STATUS_VIDEO_ERROR = 'ERROR';


export const FOLIO_VALUE = 'Superior 2025';
