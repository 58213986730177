//import React from 'react';
import React, { PureComponent } from "react";
import moment from "moment";
import { Switch, Popconfirm } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import Can from "helpers/Secure/Can";
import SortTable from "../../../helpers/Tables/sortTable";
import Empty from "../../../helpers/Empty/EmptyComponent";
import LoadingComponent from "../../../helpers/LoadingComponent";
import { newSchedules } from "../../../helpers/Globals/funciones";

import styles from "./index.module.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "antd/es/switch/style/index.css";
import "antd/es/popconfirm/style/index.css";

const { SearchBar } = Search;

class TablePlatica extends PureComponent {
  diasHoras = (cell, row) => {
    return newSchedules(cell);
  };

  percentege = (cell, row) => {
    const percentege = String(cell).slice(0, 4);
    return percentege + "%";
  };

  setStatus = (cell, row) => (
    <Can do="put" on="/talks/">
      <Popconfirm
        title="¿Cambiar estatus de la platica?"
        onConfirm={() => this.props.changeStatus(row._id)}
        okText="Si"
        cancelText="No"
      >
        <Switch
          disabled={this.props.disableSatatus}
          checked={cell}
          style={
            cell
              ? { backgroundColor: "#2ECC71" }
              : { backgroundColor: "#E74C3C" }
          }
        />
      </Popconfirm>
    </Can>
  );

  rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (
      this.props.talkSelected !== undefined &&
      String(row._id) === String(this.props.talkSelected._id)
    ) {
      style.backgroundColor = "rgba(46, 204, 113,0.5)";
    } else if (rowIndex % 2) {
      style.backgroundColor = "#fafbfe";
    }
    style.cursor = "pointer";
    style.color = "#646777";
    return style;
  };
  cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      textAlign: "center",
      verticalAlign: "middle",
      borderTop: "1px solid transparent",
    };
  };
  columns = [
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: this.cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "classRoom",
      text: "Sede",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: this.cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      formatter: (cell, row) => {
        return cell.location.name || '-';
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "percentage",
      text: "Porcentaje",
      sort: true,
      headerAlign: "center",
      csvType: String,

      editable: false,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      style: this.cellStyle,
      formatter: this.percentege,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "studentListLength",
      text: "Inscritos",
      sort: true,
      headerAlign: "center",
      csvType: String,

      editable: false,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      style: this.cellStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "quota",
      text: "Cupo",
      headerAlign: "center",
      csvType: String,
      sort: true,

      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      editable: false,
      style: {
        ...this.cellStyle(),
        width: "100px",
      },
      headerStyle: {
        width: "100px",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "schedules",
      text: "Día(s) y horarios",
      formatter: this.diasHoras,
      headerAlign: "center",
      csvType: String,
      csvText: "Día(s)",
      editable: false,
      style: this.cellStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "date",
      text: "Fecha",
      formatter: (cell, row) => {
        return moment(cell).format("DD/MM/YYYY");
      },
      headerAlign: "center",
      csvType: String,
      csvText: "Fecha",
      editable: false,
      sort: true,

      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      style: {
        ...this.cellStyle(),
        width: "120px",
      },
      headerStyle: {
        width: "120px",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.getActualTalk(row);
        },
      },
    },
    {
      dataField: "active",
      text: "Estatus",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerAlign: "center",
      align: "center",
      style: {
        ...this.cellStyle(),
        width: "100px",
      },
      headerStyle: {
        width: "100px",
      },
      formatter: this.setStatus,
    },
  ];

  render() {
    return this.props.loadingAllTalks ? (
      <LoadingComponent />
    ) : (
      <ToolkitProvider
        keyField="_id"
        data={this.props.talks}
        columns={this.columns}
        search
      >
        {(props) => (
          <>
            <div className={`${styles.search_bar}`}>
              <SearchBar
                {...props.searchProps}
                placeholder="Buscar"
                delay={300}
              />
            </div>
            <section className={styles.table_container}>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                condensed={true}
                {...props.baseProps}
                noDataIndication={
                  <Empty message="No éxisten platicas disponibles" />
                }
                rowStyle={this.rowStyle2}
              />
            </section>
          </>
        )}
      </ToolkitProvider>
    );
  }
}
export default TablePlatica;
